.login-wrap{
  position: absolute;
  width: 100%;
  height: 100%;
  background: url("../../static/images/logo-bg.jpg") no-repeat 50%;
  background-size: cover;
  .login-form{
    position: absolute;
    right: 15vw;
    top: 50%;
    width: 380px;
    transform: translateY(-50%);
    border-radius: 2px;
    background: #fff;
    overflow: hidden;
  }
  .title{
    width: 100%;
    line-height: 80px;
    text-align: center;
    font-size: 20px;
    color: #333;
    border-bottom: 1px solid #e9eaec;
    margin-bottom: 30px;
  }
}

@primary-color: #FF5103;