.home-page{
  .data-panel{
    margin-top: 20px;
    position: relative;
    .data-box{
      position: relative;
      background: #2f54eb;
      color: #fff;
      padding: 10px 20px;
      border-radius: 6px;
      font-size: 16px;
      display: inline-block;
      width: 250px;
      margin-bottom: 20px;
      .title{
        font-size: 18px;
      }
      .num{
        font-size: 30px;
        font-weight: bold;
      }
      .tag{
        margin-right: 10px;
      }
    }
    .data-box:not(:last-child){
      margin-right: 60px;
    }
    .data-box:after{
      content: '';
      width: 120px;
      height: 90px;
      background: rgba(255, 255, 255, 0.2);
      right: -20px;
      top: -20px;
      position: absolute;
      border-radius: 50%;
    }
    .data-box:before{
      content: '';
      width: 80px;
      height: 60px;
      background: rgba(255, 255, 255, 0.1);
      bottom: 20px;
      left: 60px;
      position: absolute;
      border-radius: 50%;
    }
    .data-box:first-child{
      background-image: linear-gradient(45deg, #de9d9d, #ff5103);
    }
    .data-box:nth-child(2){
      background-image: linear-gradient(45deg, #8ea6ef, #5a74e6);
    }
    .data-box:nth-child(3){
      background-image: linear-gradient(45deg, #8eb17d, #bed6b3);
    }
    .data-box:nth-child(4){
      background-image: linear-gradient(45deg, #766da7, #b6b1e6);
    }
  }
}
@primary-color: #FF5103;