.adapt-img-box{
  width: 200px;
  height: 150px;
  .ant-image{
    width: 100%;
    height: 100%;
  }
  img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
}
@primary-color: #FF5103;