.head{
  height: 100%;
  padding: 0 20px;
  .user-avatar{
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 50%;
  }
  .name{
    margin-left: 10px;
    margin-right: 5px;
    cursor: pointer;
  }
}
@primary-color: #FF5103;