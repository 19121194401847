@media (max-width: 575px){
  .filer-layout{
    .ant-form .ant-form-item .ant-form-item-label {
      margin: 0;
      display: none;
    }
  }
}
.view-table{
  .ant-table-container table > thead > tr:first-child th:first-child,
  .ant-table-container table > tbody > tr td:first-child{
    padding-left: 20px;
  }
  .ant-table-container table th{
    white-space: nowrap;
  }
}
