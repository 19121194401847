@import '~antd/dist/antd.less';
@import './antd.less';
@appSideWidth: 230px;

@font-face {
  font-family: 'iconfont';  /* Project id 2489432 */
  src: url('//at.alicdn.com/t/font_2489432_znh1cjlarpk.woff2?t=1623055851396') format('woff2'),
  url('//at.alicdn.com/t/font_2489432_znh1cjlarpk.woff?t=1623055851396') format('woff'),
  url('//at.alicdn.com/t/font_2489432_znh1cjlarpk.ttf?t=1623055851396') format('truetype');
}
.iconfont{
  font-family:"iconfont" !important;
  font-size:18px;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f6f8f9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

*{
  box-sizing: border-box;
}
.app{
  height: 100vh;
  padding-left: @appSideWidth;
}
.flex{
  display: flex;
  .shrink{
    flex-shrink: 0;
  }
  .flex-1{
    flex-grow: 1;
  }
}
.flex.align-center{
  align-items: center;
}
.flex.flex-end{
  justify-content: flex-end;
}
.flex.flex-column{
  flex-direction: column;
}
.text-ellipsis{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.relative{
  position: relative;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*布局*/
.app-side{
  width: @appSideWidth;
  background: #001529;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  .ant-menu{
    font-size: 16px;
  }
}
.app-head{
  height: 60px;
  width: 100%;
  background: #fff;
  box-shadow: 2px 0 2px 1px #eee;
  position: relative;
  z-index: 1;
  font-size: 16px;
}
.app-content{
  height: 100%;
}
.app-main{
  overflow: auto;
  padding: 20px;
  min-width: 650px;
}
.app-logo{
  height: 60px;
  color: #fff;
  font-size: 20px;
  .logo{
    height: 40px;
    width: auto;
    margin-left:20px;
  }
  .name{
    margin-left: 10px;
    font-size: 16px;
  }
}
.app-menu{
  overflow: auto;
}
.align-right{
  text-align: right;
}
.align-center{
  text-align: center;
}
/*表单*/
.filer-layout{
  background: #fff;
  padding: 20px;
}
/*弹层*/
.modal-title{
  padding: 0 15px;
  position: relative;
  font-size: 16px;
}
.modal-title:after{
  content: '';
  height: 100%;
  width: 3px;
  left: 0;
  position: absolute;
  top: 0;
  background: @primary-color;
}
.modal-title.m-b, .modal-content.m-b{
  margin-bottom: 15px;
}
.modal-content{
  padding: 0 15px;
}
.modal-label{
  //text-align: right;
  padding: 10px 0;
  color: #666;
}
.modal-value{
  padding: 10px 0;
  color: #222;
}
.modal-footer{
  text-align: right;
}
.filter-form-control{
  padding-left: 10px;
}
.color-4a67de{
  color: #4a67de
}
.color-FF681D{
  color: #FF681D;
}
.pointer{
  cursor: pointer;
}
/*切换标签*/
.tab-box{
  .tab-item{

  }
}
.panel{
  padding: 10px 20px;
  background: #fff;
  margin-bottom: 10px;
}
.label-color{
  color: #666;
}
.label-value{
  color: #222;
}
.col-label{
  color: #666;
  width: 100%;
  padding: 10px;
}
.ant-modal-body {
  .col-label{
    padding: 10px 0;
  }
  .text-list:first-child{
    .col-label, .col-value{
      padding-top: 0;
    }
  }
}
.col-value{
  padding: 10px 0;
  color: #222;
}
.text-title{
  padding: 0 15px;
  position: relative;
  font-size: 16px;
  margin-bottom: 10px;
}
.text-title.offset-top{
  margin-top: 20px;
}
.text-title:after{
  content: '';
  height: 100%;
  width: 3px;
  left: 0;
  position: absolute;
  top: 0;
  background: @primary-color;
}

.table-my{
  td{
    border: solid 1px #e9eaec;
    padding: 10px;
  }
}
.is-link{
  color: #FF5103;
  cursor: pointer;
}
.nowrap{
  white-space: nowrap;
}
.preview-box-tem{
  display: inline-block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin-left: 10px;
  .iconfont{
    color: #FF5103;
  }
  .img-box{
    transform: translate(-50%, -50%);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
  }
}
@primary-color: #FF5103;