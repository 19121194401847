:local{
  .addr-panel{
    position: relative;
    background: #fff;
    border-radius: 12px;
    .addr-item{
      background: #fff;
    }
    .origin-icon-box{
      height: 100%;
      width: 28px;
      transition: .3s;
    }
    .origin-icon, .destination-icon, .origin-dot{
      background: #000000;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 50%;
      color: #fff;
      font-size: 12px;
      position: relative;
      z-index: 2;
    }
    .origin-dot{
      position: relative;
      background: #fff;
    }
    .origin-dot:after{
      content: '';
      background: #DDDFE3;
      position: absolute;
      left: 50%;
      top: 50%;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-left: -6px;
      margin-top: -6px;
    }
    .destination-icon{
      background: #FF8F51;
    }
    .my-addr-icon{
      width: 34px;
      height: 34px;
    }
    .addr-panel-content{
      text-align: left;
      .addr-name{
        font-weight: bold;
        color: #171B25;
      }
      .add-des{
        font-size: 13px;
        color: #787A82;
        margin-bottom: 6px;
      }
    }
    .addr-item:not(:last-child){
      margin-bottom: 7px;
    }
  }

}
@primary-color: #FF5103;